import React, { useEffect } from 'react';
import DefaultApi from "../generated/src/api/DefaultApi";
import {ApiClient} from "../generated/src";

const ApiRequestComponent = () => {

  useEffect(() => {
    const fetchData = async () => {
      try {
        var client = new DefaultApi(new ApiClient(window.location.origin))
        const response = await client.checkSessionApiRbiCheckSessionGetWithHttpInfo() // Replace with actual method
        console.log('Data fetched:', response);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>

    </div>
  );
};

export default ApiRequestComponent;
